
import {  watch } from 'vue';
import config from 'config'

function getValue(store,i18n,data)
{
    if(typeof data === 'string'){
        return data;
    }    
    if(typeof data !== 'object'){
        return '';
    }
    if(Object.prototype.hasOwnProperty.call(data,'text')){
        return data.text;
    }
    if(Object.prototype.hasOwnProperty.call(data,'i18n')){
        return i18n.t(data.i18n);
    }
    if(Object.prototype.hasOwnProperty.call(data,'store')){
        return store.getters[data.store];
    }
    return '';
}

function setMetaData(router,i18n,store)
{
    const nearestWithTitle = router.currentRoute.value.matched.slice().reverse().find(r => r.meta && r.meta.title);

    const nearestWithMeta = router.currentRoute.value.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    let title = "";
    if(nearestWithTitle) {
        title = getValue(store,i18n,nearestWithTitle.meta.title);
        if(config.title){
            title += ' | '+getValue(store,i18n,config.title);
        }
    } else {
        title = getValue(store,i18n,config.title);
    }
    document.title = title;


    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if(nearestWithMeta){
        nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, getValue(store,i18n,tagDef[key]));
            });

            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        }).forEach(tag => document.head.appendChild(tag));
    }

    // const img = require('@zapis_ostrov/assets/logo.png');
    // const tag = document.createElement('link');
    // tag.setAttribute('rel', 'shortcut icon');
    // tag.setAttribute('type', 'image/png');
    // tag.setAttribute('data-vue-router-controlled', '');
    // tag.setAttribute('href', img);
    // document.head.appendChild(tag)

    // if(router.currentRoute.value.meta.editor){
    //     const script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = require('tinymce/tinymce.min.js');    
    //     document.head.appendChild(script)
    // }
}

export default function(router,i18n,store)
{

    setMetaData(router,i18n,store);
    watch(() => router.currentRoute.value, () => setMetaData(router,i18n,store));
    
    return {
        setMetaData,
    };
}
