<template>
    <footer class="main-footer">
        <div class="page-container">
            <div class="profile">
                <img src="@agneweb/assets/photo.webp" alt="Profilová fotografie" width="200" height="200">
            </div>
            <Block :content="getLayoutContent('footer')" />
            <div>
                <Menu :menu_key="'header_menu'" :horizontal="false" :mobile_hidden="false"/>
                <Menu :menu_key="'footer_menu'" :horizontal="false" :mobile_hidden="false"/>
            </div>
            
        </div>
        <Copyright />
    </footer>
</template>

<script>
import Block from "@common/components/cms_app/Block";
import useContent from "@common/composable/cms_app/Content";
import Menu from "@common/components/cms_app/menu/Menu"
import Copyright from '@common/components/layout/Copyright';

export default {
    name: "MainFooter",
    components: {
        Block,
        Menu,
        Copyright
    },
    setup(){
        const { getLayoutContent } = useContent();

        return {
            getLayoutContent
        }
    }
}
</script>

<style scoped>
.main-footer{
    padding: 2rem 0 0 0;
    background: #344b1b;
    color: #E2E8D4;
    color: var(--light-color);
    
    background-image: linear-gradient(316deg, #1d290f, transparent);
    box-shadow: 0 0 10px -2px #344b1b;
    margin-top: 4rem;
}
.page-container{
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    min-height: 15rem;;
    padding: 2rem 0;
    align-items: center;
}
.main-footer ::v-deep(ul.menu-list){
    margin: 0;
    padding: 0;
}

.main-footer ::v-deep(a){
    color: #E2E8D4;
    color: var(--light-color);
}

.main-footer ::v-deep(ul.menu-list a){
    display: block;
    padding: 0.5rem 1.5rem;
    margin: 0.5rem;
    background: #344b1b;
    background-image: radial-gradient(at 30% 30%,rgb(37 97 47) 30%,rgb(52 75 27 / 0%));
    box-shadow: 0 0 10px -2px #1d290f;
}
.profile img{
    border-radius: 50%;
    
    box-shadow: 1px 1px 10px -1px #212e11;
    border: 1px solid #223011;
}

@media only screen and (max-width: 800px) {
    .page-container{
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
}
</style>
