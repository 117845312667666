import axios from 'axios';
import { logout,getToken } from '@common/helpers';

export function httpService(config,{
    router = null,
    errorPageName = 'error'
} = {
    router: null,
    errorPageName: 'error'
}){
    /** Creating the instance for axios */
    const httpClient = axios.create(config);

    /** Auth token interceptors */
    const authInterceptor = config => {
        config.headers.atoken = `Bearer ${getToken()}`;
        return config;
    };


    /** Adding the request interceptors */
    httpClient.interceptors.request.use(authInterceptor);

    /** Adding the response interceptors */
    httpClient.interceptors.response.use(
        response => {
            // console.log(response);
            /** TODO: Add any response interceptors */
            return response;
        },
        error => {
            console.log(error.response)
            if(!error.response){
                return Promise.reject(error.message);
            }
            if (error.response.status === 401){
                logout();
            }
            if (error.response.status === 302){
                console.log('todo: redirect');
            }
            if (error.response.status === 404 && router !== null){
                router.push({name: errorPageName,params: { error_code: error.response.status }});
                return;
            }
            const data = error.response.data;            
            const errorData = (data && data.message) || (data && data.errors) || error.response.statusText;
            return Promise.reject(errorData);
        }
    );
    return httpClient;
}

