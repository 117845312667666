<template>    
    <button class="btn" :disabled="loading">
        <span v-show="!loading">
            <slot></slot>
        </span>
        
        <BaseLoader v-show="loading"/>
    </button>
</template>

<script>
export default {
    name: "BaseButton",
    props: {
        loading: {
            type: Boolean,
            default: () => false
        }
    }

}
</script>

<style>
.btn{
    border: none;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    transition: var(--transition-opacity);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2),
        0 4px 15px 0 rgba(0,0,0,0.19),
        inset -1px -1px 5px rgba(0,0,0,0.3);
    max-width: 100%;
    background: #d0d7dc;
    border-radius: 0.5rem
}

.btn.btn-primary{
    background-color: rgb(134 193 241);
}


.btn.btn-success{
    background-color: #068906;
    color: white
}

.btn.btn-danger{
    background-color: #a10000;
    color: white
}

.btn.btn-warning{
    background-color: rgb(251 195 92);
    color: black;
}


.btn.btn-xl{
    font-size: 1.3rem;
    width: 20rem; 

}
        
.btn:hover{
    opacity: 0.8;
} 

</style>
