import { createStore } from "vuex";

import alert  from '@common/store/alert.module';
import Project  from '@common/store/app/project.module';
import Page  from '@common/store/app/cms/page.module';

export function setupStore(router){
    return createStore({
        state: {},
        mutations: {},
        actions: {},
        modules: {
            alert: alert,
            project: Project(router),
            page: Page(router),
        }
    });
}
