
const createMessage = ({state,type,text = '',i18n = ''}) => {
    if(typeof text !== 'string'){
        text = text.toString();
    }
    if(typeof i18n !== 'string'){
        i18n = i18n.toString();
    }
    const message = {
        type,
        text,
        i18n
    }
    state.messages.push(message);
    setInterval(() => {
        let index = state.messages.indexOf(message);
        if (index !== -1) {
            state.messages.splice(index, 1);
        }
    },5000)
}

const state = {
    messages: [],
};

const getters = {
    messages: (state) => state.messages
}

const actions = {
    success({ commit }, messages) {
        commit('clear');
        if(typeof messages === 'string'){
            commit('success', messages);
        } else if(typeof messages === 'object'){
            Object.values(messages).forEach(message => {
                commit('success', message);
            });
        }
    },
    error({ commit }, messages) {
        commit('clear');
        if(typeof messages === 'string'){
            commit('error', messages);
        } else if(typeof messages === 'object'){
            Object.values(messages).forEach(message => {
                commit('error', message);
            });
        }
    },
    successI18n({ commit }, messages) {
        commit('clear');
        if(typeof messages === 'string'){
            commit('successI18n', messages);
        } else if(typeof messages === 'object'){
            Object.values(messages).forEach(message => {
                commit('successI18n', message);
            });
        }
    },
    errorI18n({ commit }, messages) {
        commit('clear');
        if(typeof messages === 'string'){
            commit('error', messages);
        } else if(typeof messages === 'object'){
            Object.values(messages).forEach(message => {
                commit('error', message);
            });
        }
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    success(state, text) {
        createMessage({
            state,
            type: 'alert-success',
            text,
        });
    },
    error(state, text) {
        createMessage({
            state,
            type: 'alert-danger',
            text,
        });
    },
    successI18n(state, i18n) {
        createMessage({
            state,
            type: 'alert-success',
            i18n,
        });
    },
    errorI18n(state, i18n) {
        createMessage({
            state,
            type: 'alert-danger',
            i18n,
        });
    },
    clear(state) {
        state.messages = [];
    },
    close: (state,index) => {
        if(state.messages.length > index){
            state.messages.splice(index, 1); 
        }
    }

};

export  default  {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
