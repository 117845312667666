<template>
    <ul class="menu-list" role="menu">
        <li 
            v-for="item in items" :key="item.code" 
            class="nav-item" :class="'level'+level.toString()" 
            role="none"
        >
            <a role="menuitem" v-if="item.type === 0 && item.direct_link" :href="item.direct_link">{{item.name}}</a>
            <RouterLinkLocal role="menuitem" class="nav-link" v-else-if="item.type === 0" :link="getLink(item)">{{item.name}}</RouterLinkLocal>
            <span v-else :class="{title: item.type === 1, label: item.type === 2, separator: item.type === 3}">{{item.name}}</span>
            <MenuList 
                v-if="num_of_layers !== 0 && Array.isArray(item.menu_items) && item.menu_items.length > 0" 
                :items="item.menu_items"
                :num_of_layers="num_of_layers-1"
                :layer_hidden="layer_hidden-1"
                :class="{dropdown: layer_hidden <= 0}"
                :level="level+1"
            />
        </li>
    </ul>
</template>

<script>
export default {
    name: "MenuList",
    props: {
        items: {
            type: Array,
            required: true,
        },
        level: {
            type: Number,
            default: () => 0,
        },
        num_of_layers: {
            type: Number,
            default: () => -1,
        },
        layer_hidden: {
            type: Number,
            default: () => 0,
        }
    },
    setup(){
        const getLink = (item) => {
            if(item.url){
                return '!'+item.url;
            }
            if(item.contentPageCode){
                return 'url.page/!'+item.contentPageCode;
            }
            return "";
        };

        return {
            getLink
        }
    }
}
</script>

<style scoped>
.nav-item{
    position: relative;
}
.dropdown{
    position: absolute;
    top: -1000%;
    left: 0;
    /* width: 100vw; */
    z-index: -1;
    margin-left: 0;
    padding: 0;
}
.dropdown .nav-link{
    opacity: 0;
    transition: var(--transition-opacity);
}
.nav-item:hover>.dropdown,
.nav-item:focus>.dropdown,
.nav-item:focus-within>.dropdown{
    top: 1.5rem;
    left: 2rem;
    z-index: 10;
}
.nav-item:hover>.dropdown>.nav-item>.nav-link,
.nav-item:focus>.dropdown>.nav-item>.nav-link,
.nav-item:focus-within>.dropdown>.nav-item>.nav-link{
    opacity: 1;
}   
.nav-item>.dropdown>.nav-item>.nav-link:hover{
    opacity: 0.8;
}
 

</style>