import { createApp } from "vue";
import App from "./App.vue";
import { setupI18n } from './i18n'
import { setupRouter } from './router'
import { setupStore } from './store'

import { registerBaseComponents } from '@common/helpers';

const i18n = setupI18n();
const router = setupRouter(i18n);
const store = setupStore(router);


let app = createApp(App);

app.use(store);

app.use(i18n);
app.use(router);

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  // Use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js');
  });
}

registerBaseComponents(app);

router.isReady().then(() => {
  app.mount('#app');
})
