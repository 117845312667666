<template>
    <header class="main-header">
        <div class="page-container">
            <RouterLinkLocal class="button top-logo" :link="''"><img height="24" width="24" alt="AgneWeb logo" src="@agneweb/assets/logo40_40.webp"><span>AgneWeb</span></RouterLinkLocal>
            <div class="stretch"></div>
            <Menu id="header_menu" :menu_key="'header_menu'" />
        </div>
        
    </header>
</template>

<script>
import Menu from "@common/components/cms_app/menu/Menu"

export default {
    name: "MainHeader",
    components: {
        Menu
    },
    setup(){
        return {
        }
    }
}
</script>

<style scoped>

.main-header {
    white-space: nowrap;
    background-color: #177542;
    background-color: var(--primary-color);
    background-image:  
         radial-gradient(at 40% 40%,rgb(23 117 66 / 70%) 30%,rgb(52 75 27 / 56%)),
        var(--bg-pattern);
    background-size:   100% 100%,var(--bg-pattern-size);
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 1px 1px 10px -2px #344b1b;
}

.page-container{
    text-align: right;
    display: flex;
    align-items: stretch;
    height: 2.5rem;;
}

.main-header a img{
    border: 2px solid white;
    border: 2px solid var(--light-color);
    border-radius: 50%;
}
.main-header .menu ::v-deep(.button){
    background: #dfe5d147;
}

/* .main-header a.router-link-exact-active {
  color: #42b983;
} */
.button{
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition:  background-color var(--transition-ease);
    height: 100%;
    margin-right: 0.5rem;
    overflow: hidden;
}
.top-logo{
    text-decoration: none;
    font-family: 'Orbitron', sans-serif;
    color: white;
    color: var(--light-color);
    text-shadow: 1px 1px 4px #125E34;
    /* border-radius: 1rem; */
    position: relative;
    z-index: 1;
}
.top-logo::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: radial-gradient(#365446 0%,transparent 80%,transparent 100%);
    z-index: -1;
    transition: opacity var(--transition-ease);
    opacity: 0;
}
.top-logo>span{
    position: relative;
    top: -0.3rem;
}

.top-logo img{
     height: 100%;
    margin-right: 0.5rem
}

.top-logo:hover{
    opacity: 1;
    /* background-color:  #365446;
    background-image: radial-gradient(#365446 0%,var(--primary-color) 80%,var(--primary-color) 100%); */
}
.top-logo:hover::before{
    opacity: 1;
  /* z-index: 1; */
}
.stretch{
    flex-grow: 1;
}

#header_menu ::v-deep(.nav-item a){
    background: #E2E8D4;
    background: var(--light-color);
    color: #344b1b;
    color: var(--dark-color);
    padding: 0.2rem;
    margin: 0;
    width: 6rem;
    text-align: center;
    font-weight: 700;
    display: block;
    
    box-shadow: 1px 1px 10px -2px #344b1b;
}

#header_menu ::v-deep(.nav-item.level0>a){
    margin: 0 0.5rem;
}

#header_menu ::v-deep(.nav-item.level0 .menu-list){
    box-shadow: 0px 0px 5px -1px var(--dark-color);
    border: 1px solid gray;
    background: #E2E8D4;
    background: var(--primary-color);
}

@media only screen and (max-width: 800px) {
    #header_menu ::v-deep(.menu-list.showmenu){
        background-color: #177542;
        background-color: var(--primary-color);
        padding: 0;
        margin: 0;
        height: initial;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    #header_menu ::v-deep(.nav-item a){
        width: initial
    }

    #header_menu ::v-deep(.nav-item.level0>a){
        margin: 0.1rem;
        padding: 0.5rem; 
    }
}

</style>
