<template>
    <transition appear :name="transitionName">
        <div class="Cookie" :class="[containerPosition, cookieTheme]" v-if="isOpen">
            <slot :accept="accept" :close="close" :decline="decline" :open="open">
                <div class="Cookie__content">
                    <slot name="message">{{ message }}</slot>
                </div>
                    <a :target="target" :href="buttonLink" v-if="externalButtonLink" :class="buttonClass">{{ buttonLinkText }}</a>
                    <RouterLinkLocal :link="buttonLink" v-if="internalButtonLink" :class="buttonClass">{{ buttonLinkText }}</RouterLinkLocal>
                <div class="Cookie__buttons">
                    <BaseButton v-if="buttonDecline" :class="buttonDeclineClass" class="btn-danger" @click="decline">{{ buttonDeclineText }}</BaseButton>
                    <BaseButton :class="buttonClass" class="btn-success" @click="accept">{{ buttonText }}</BaseButton>
                </div>
            </slot>
        </div>
    </transition>
</template>

<script>
import * as Cookie from 'tiny-cookie'
import { useI18n } from 'vue-i18n'

const STORAGE_TYPES = {
    local: 'localStorage',
    cookies: 'cookies'
}
// original source https://github.com/apertureless/vue-cookie-law/blob/develop/src/components/CookieLaw.vue
export default {
    name: 'CookieBar',
    props: {
        buttonText: {
            type: String,
            default: () => {
                const i18n = useI18n();
                return i18n.t('controls.got_it');
            } 
        },
        buttonDecline: {
            type: Boolean,
            default: false
        },
        buttonDeclineText: {
            type: String,
            default: () => {
                const i18n = useI18n();
                return i18n.t('controls.decline');
            } 
        },
        buttonLink: {
            type: String,
            required: false
        },
        buttonLinkType: {
            type: String,
            required: false,
        },
        buttonLinkText: {
            type: String,
            default: () => {
                const i18n = useI18n();
                return i18n.t('controls.more_info');
            } 
        },
        buttonLinkNewTab: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: () => {
                const i18n = useI18n();
                return i18n.t('cms.cookie_consent');
            } 
        },
        theme: {
            type: String,
            default: 'base'
        },
        /**
         * Cookie Container position
         * bottom, top
         * @type {Object}
         */
        position: {
            type: String,
            default: 'bottom'
        },
        /**
         * Transition name has following possibilities
         * slideFromBottom
         * slideFromTop
         * fade
         * @type {Object}
         */
        transitionName: {
            type: String,
            default: 'slideFromBottom'
        },
        buttonClass: {
            type: String,
            default: 'Cookie__button'
        },
        buttonDeclineClass: {
            type: String,
            default: 'Cookie__button--decline'
        },
        storageName: {
            type: String,
            default: 'cookie:accepted'
        },
        storageType: {
            type: String,
            default: STORAGE_TYPES.local
        },
        cookieOptions: {
            type: Object,
            default: () => {},
            required: false
        }
    },
    data () {
        return {
            supportsLocalStorage: true,
            isOpen: false
        }
    },
    computed: {
        containerPosition () {
            return `Cookie--${this.position}`
        },
        cookieTheme () {
            return `Cookie--${this.theme}`
        },
        externalButtonLink () {
            return typeof this.buttonLink === 'string' && this.buttonLink.length && this.buttonLinkType === "external"
        },
        internalButtonLink () {
            return typeof this.buttonLink === 'string' && this.buttonLink.length && this.buttonLinkType === "internal"
        },
        target () {
            return this.buttonLinkNewTab ? '_blank' : '_self'
        },
        canUseLocalStorage () {
            return this.storageType === STORAGE_TYPES.local && this.supportsLocalStorage
        }
    },
    created () {
        if (this.storageType === STORAGE_TYPES.local) {
            // Check for availability of localStorage
            try {
                const test = '__vue-cookielaw-check-localStorage'
                if (typeof window !== 'undefined') {
                  window.localStorage.setItem(test, test)
                  window.localStorage.removeItem(test)
                }
            } catch (e) {
                console.info('Local storage is not supported, falling back to cookie use')
                this.supportsLocalStorage = false
            }
        }
        if (!this.getVisited()) {
            this.isOpen = true
        }
    },
    mounted () {
        if (this.isAccepted()) {
            this.$emit('accept')
        }
    },
    methods: {
        setVisited () {
            if (this.canUseLocalStorage) {
                localStorage.setItem(this.storageName, true)
            } else {
                Cookie.set(this.storageName, true, { ...this.cookieOptions, expires: '1Y' })
            }
        },
        setAccepted () {
            if (this.canUseLocalStorage) {
                localStorage.setItem(this.storageName, true)
            } else {
                Cookie.set(this.storageName, true, { ...this.cookieOptions, expires: '1Y' })
            }
        },
        setDeclined () {
            if (this.canUseLocalStorage) {
                localStorage.setItem(this.storageName, false)
            } else {
                Cookie.set(this.storageName, false, { ...this.cookieOptions, expires: '1Y' })
            }
        },
        getVisited () {
            let visited = false
            if (this.canUseLocalStorage) {
                visited = localStorage.getItem(this.storageName)
            } else {
                visited = Cookie.get(this.storageName)
            }
            if (typeof visited === 'string') {
                visited = JSON.parse(visited)
            }
            return !(visited === null || visited === undefined)
        },
        isAccepted () {
            let accepted = false
            if (this.canUseLocalStorage) {
                accepted = localStorage.getItem(this.storageName)
            } else {
                accepted = Cookie.get(this.storageName)
            }
            if (typeof accepted === 'string') {
                accepted = JSON.parse(accepted)
            }
            return accepted
        },
        accept () {
            this.setVisited()
            this.setAccepted()
            this.isOpen = false
            this.$emit('accept')
        },
        close () {
            this.isOpen = false
            this.$emit('close')
        },
        decline () {
            this.setVisited()
            this.setDeclined()
            this.isOpen = false
            this.$emit('decline')
        },
        revoke () {
            if (this.canUseLocalStorage) {
                localStorage.removeItem(this.storageName)
            } else {
                Cookie.remove(this.storageName)
            }
            this.isOpen = true
            this.$emit('revoke')
        },
        open () {
            if (!this.getVisited()) {
                this.isOpen = true
            }
        }
    }
}
</script>

<style scoped>
.Cookie {
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 9999;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    flex-direction: column;
    padding: 1.250rem;
    background: #F1F1F1;
    color: #232323;
    
    background: var(--backgroundColor);
    color: var(--fontColor);
}
.Cookie a {
    color: #232323;
    color: var(--fontColor);
    text-decoration: underline;
}
.Cookie > * {
    margin: 1rem 0;
    align-self: center;
}


@media screen and (min-width: 600px) {
    .Cookie {
        flex-flow: row;
    }
    .Cookie > * {
        margin: 0;
    }
    .Cookie__buttons{
        flex-direction: row;
    }
    .Cookie__buttons > * {
        margin: 0 1rem;
    }
}
.Cookie--top {
    top: 0;
    left: 0;
    right: 0;
}
.Cookie--bottom {
    bottom: 0;
    left: 0;
    right: 0;
}

.Cookie__buttons {
    display: flex;
    flex-direction: column;
}

.Cookie__buttons > * {
    margin: 0.5rem 0;
}

.Cookie__button {
    /* cursor: pointer;
    align-self: center; */
    white-space: nowrap;
    padding: 0.625rem 3.125rem;
    /* border: 0; */
    font-size: 1rem;
    /* color: #fff;
    color: var(--buttonFontColor);
    border-radius: 0;
    border-radius: var(--buttonRadius);
    background: #97D058;
    background: var(--buttonBackgroundColor); */
}
/* .Cookie__button:hover{
    background: darken(#97D058, 10%);
    background: darken(var(--buttonBackgroundColor), 10%);
} */
.Cookie__button--decline {
    cursor: pointer;
    align-self: center;
    white-space: nowrap;
    background: transparent;
    padding: 0.625em 3.125em;
    border: 0;
    font-size: 1em;
    color: darken(#F1F1F1, 50%);
    color: darken(var(--backgroundColor), 50%);
    border-radius: 0;
    border-radius: var(--buttonRadius);
}
/* .Cookie__button--decline:hover{
    background: darken(#F1F1F1, 15%);
    background: darken(var(--backgroundColor), 15%);
} */
.Cookie--base{
    --backgroundColor:  #F1F1F1;
    --fontColor: #232323;
    --buttonBackgroundColor: #97D058;
    --buttonFontColor: #fff;
    --buttonRadius: 0;
}
.Cookie--rounded{
    --backgroundColor:  #F1F1F1;
    --fontColor: #232323;
    --buttonBackgroundColor: #97D058;
    --buttonFontColor: #fff;
    --buttonRadius: 1rem;
}
.Cookie--blood-orange{
    --backgroundColor:  #424851;
    --fontColor: #fff;
    --buttonBackgroundColor: #E76A68;
    --buttonFontColor: #fff;
    --buttonRadius: 0;
}
.Cookie--blood-orange--rounded{
    --backgroundColor:  #424851;
    --fontColor: #fff;
    --buttonBackgroundColor: #E76A68;
    --buttonFontColor: #fff;
    --buttonRadius: 1rem;
}
.Cookie--dark-lime{
    --backgroundColor:  #424851;
    --fontColor: #fff;
    --buttonBackgroundColor: #E76A68;
    --buttonFontColor: #fff;
    --buttonRadius: 0;
}
.Cookie--dark-lime--rounded{
    --backgroundColor:  #424851;
    --fontColor: #fff;
    --buttonBackgroundColor: #97D058;
    --buttonFontColor: #fff;
    --buttonRadius: 1rem;
}

.slideFromTop-enter, .slideFromTop-leave-to {
    transform: translate(0px, -12.500em);
}
.slideFromTop-enter-to, .slideFromTop-leave {
    transform: translate(0px, 0px);
}
.slideFromBottom-enter, .slideFromBottom-leave-to {
    transform: translate(0px, 12.500em);
}
.slideFromBottom-enter-to, .slideFromBottom-leave {
    transform: translate(0px, 0px);
}
.slideFromBottom-enter-active,
.slideFromBottom-leave-active,
.slideFromTop-enter-active,
.slideFromTop-leave-active {
    transition: transform .4s ease-in;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}
.fade-enter, .fade-leave-to {
    opacity: 0
}
</style>