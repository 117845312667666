export function logout() {
    if(isLoggedIn()){
        localStorage.removeItem('ACCESS_TOKEN');
        location.reload();
    }
}

export function isLoggedIn() {
    return !!localStorage.getItem('ACCESS_TOKEN')
}
export function getToken() {
    return localStorage.getItem('ACCESS_TOKEN')
}
