<template>
    <BlockDefault 
        v-if="template === 'default'" 
        class="block" :class="[content.class]" 
        :components="components"
        :content="content"
    />
    <component 
        class="block" :class="[content.class]" 
        v-else-if="getCustomComponent(template)"
        :is="getCustomComponent(template)"
        :content="content"
    />
</template>

<script>
import { computed, defineAsyncComponent, inject } from 'vue';


export default {
    name: "Block",
    components: {
        BlockDefault: defineAsyncComponent(() => import(/* webpackChunkName: "block-default" */ /* webpackPreload: true */'@common/components/cms/blocks/BlockDefault')),
    },
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    setup(props){
        const template = computed(() => {
            if(!props.content || typeof props.content.typeCode !== 'string'){
                return null;
            }
            let cells = props.content.typeCode.split('_');

            return cells[0];
        });

        const customBlocks = inject('customBlocks',{});
        
        const components = computed(() => {
            let _components = {
                text: defineAsyncComponent(() => import(/* webpackChunkName: "text-cell" */ /* webpackPreload: true */'@common/components/cms_app/cells/TextCell')),
                image: defineAsyncComponent(() => import(/* webpackChunkName: "image-cell" */ /* webpackPreload: true */'@common/components/cms_app/cells/ImageCell')),
                form: defineAsyncComponent(() => import(/* webpackChunkName: "form-cell" */ /* webpackPreload: true */'@common/components/cms_app/cells/FormCell')),
                ...customBlocks
            };   

            return _components;
        });

        
        const getCustomComponent = (type) => customBlocks[type] ?? null;

        return {
            template,
            components,
            getCustomComponent
        }
    }
}
</script>

<style scoped>
</style>
