<template>
    <div class="main-layout">
        <MainHeader id="main-header" @toggle-menu="toggleMenu()"/>
        <main id="main-container" v-bind="$attrs">
            <Alert />
            <router-view id="main-content" v-bind="$attrs" keep-alive/>
        </main>
        <MainFooter id="main-footer" />
        <CookieBar 
            theme="dark-lime"
            buttonLink="url.privacy_policy"
            buttonLinkType="internal"
        ></CookieBar>
    </div>
</template>

<script>
import Alert from "@common/components/Alert";
import MainHeader from "@agneweb/components/layout/MainHeader";
import MainFooter from "@agneweb/components/layout/MainFooter";
import CookieBar from "@common/components/cms_app/CookieBar";

export default {
    name: "MainLayout",
    components: {
        Alert,
        MainHeader,
        MainFooter,
        CookieBar
    },
    setup(){

    }
}
</script>

<style scoped>
.main-layout{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content minmax(max-content,1fr) max-content;
    grid-template-areas: 
        'header'
        'container'
        'footer'
        ;
    grid-gap: 0;
    justify-items: stretch; 
    min-height: 100vh;
    min-height: 100vh;  
    margin: 0 auto;  
    /* width: 60rem; */
    max-width: 100%;
}
#main-header {
    grid-area: header;
}
#main-container{
    grid-area: container;
    display: flex;
    flex-direction: column;
    width: 100%;
}

#main-content{
    min-height: calc(100% - 2.5rem);
    padding: 0;
}
#main-footer{
  grid-area: footer;
}

::v-deep(.page-view>h1){
    margin-top: 2rem;
    border-bottom: 2px solid #b8bdae;
    padding: 0.5rem 2rem;
    font-size: 1.6rem;
}

::v-deep(.btn_link){
    background-color: #177542;
    background-color: var(--dark-color);
    color: #E2E8D4;
    color: var(--light-color);
    box-shadow: 1px 1px 10px -1px #344b1b;
    
    background-image:  
         radial-gradient(at 30% 30%,rgb(23 117 66 / 100%) 30%,rgb(52 75 27 / 0%));
    background-size:   100% 100%;
    text-shadow: 1px 1px 10px #344b1b;
    padding: 1rem;
}
::v-deep(.contact_block){
    background: grey;
    padding: 1rem;
    min-height: 15rem;
    color: #E2E8D4;
    color: var(--light-color);
}

::v-deep(.left_image),::v-deep(.right_image){
    margin-top: 4rem;
    margin-bottom: 2rem;
}

::v-deep(.right_image){
    flex-direction: row-reverse;
    text-align: right;
}

::v-deep(.left_image .text-cell:first-child),::v-deep(.right_image .text-cell:first-child),::v-deep(.prices_variants .text-cell){
    background-color: #344b1b;
    color: #E2E8D4;
    color: var(--light-color);
    box-shadow: 1px 1px 10px -2px #344b1b;
    
    background-image:  
         radial-gradient(at 40% 40%,rgb(23 117 66 / 70%) 30%,rgb(52 75 27 / 56%));
    text-shadow: 1px 1px 10px #344b1b;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
::v-deep(.left_image h2), ::v-deep(.right_image h2){
    font-size: 1.3rem;
    margin-bottom: 0;
}
::v-deep(.left_image .text-cell:last-child),::v-deep(.right_image .text-cell:last-child),::v-deep(.prices_variants .text-cell:first-child),::v-deep(.prices_variants .text-cell:last-child){
    background-color: #c8d6a8;
    box-shadow: 1px 1px 10px -2px #344b1b;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-shadow: none;
    color: #344b1b;
    color: var(--dark-color);
    background-image: none;
    margin: 1.5rem 0;
}
::v-deep(.left_image .text-cell:last-child),::v-deep(.right_image .text-cell:last-child){
    margin: 4rem 0;

}
::v-deep(.block.prices_variants){
    margin: 0 0 4rem 0;
}
::v-deep(.bricks_block .text-cell){
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
::v-deep(.bricks_block .text-cell>div){
    background: #c8d6a8;
    /* color: #E2E8D4; */
    padding: 1rem;
    width: 10rem;
    font-size: 1.2rem;
    text-align: center;
    box-shadow: 0px 0px 5px -1px #344b1b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.5rem;
    background-image: radial-gradient(at 30% 30%,rgb(226 232 212 / 38%) 30%,rgb(52 75 27 / 0%));
}

::v-deep(.green_block),::v-deep(.references img){
    background-color: #177542;
    background-color: var(--primary-color);
    color: #E2E8D4;
    color: var(--light-color);
    box-shadow: 1px 1px 10px -2px #344b1b;
    
    background-image:  
         radial-gradient(at 40% 40%,rgb(23 117 66 / 70%) 30%,rgb(52 75 27 / 56%)),
        var(--bg-pattern);
    background-size:   100% 100%,var(--bg-pattern-size);
    text-shadow: 1px 1px 10px #344b1b;
    padding: 1rem;
    margin: 1rem;
}
::v-deep(.green_block){
    padding: 1rem;
    margin: 1rem;
}
::v-deep(.references .text-cell img){
    margin: -2rem -2rem 0 -2rem;
    width: calc(100% + 4rem);
    max-width: calc(100% + 4rem);
    padding: 0;
}
::v-deep(.references .text-cell a:hover){
    opacity: 1;
}
::v-deep(.references>div){
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    margin: 2rem;
    background: #c8d6a8;
    box-shadow: 1px 1px 10px -2px #344b1b;
}
::v-deep(.references p){
    padding: 0;
    margin: 0;
}

::v-deep(.references){
    padding: 1.5rem;
    background: #d5dcc3;
    margin: 0 -2rem;
}
::v-deep(.green_header h2){
    padding: 1rem 3rem;
    margin: 7rem -2rem 0;
    background: #1a703d;
    color: #e2e8d4;
    background-image: radial-gradient(at 40% 40%,rgb(23 117 66 / 70%) 30%,rgb(52 75 27 / 56%));
    text-shadow: 1px 1px 10px #344b1b;
}
::v-deep(.references ul){
    list-style-type: square;
}
::v-deep(.references h3){
    border-bottom: 2px solid #a0ab87;
    margin: 0 0 0.5rem 0;
    padding: 0.5rem;
}
::v-deep(.references h3>a){
    color: #344b1b;
    color: var(--dark-color);
}
::v-deep(.references h3>a:hover){
    opacity: 0.8;
}
@media only screen and (max-width: 1200px) {
    #main-content{
        min-width: initial;
        width: 100%;
    }
    
}

@media only screen and (max-width: 800px) {
    
    ::v-deep(.page-view>h1){
        margin: 1rem 0.5rem;
        padding: 0.5rem 1rem;
        font-size: 1.5rem;
    }

    
    ::v-deep(.references .text-cell){
        width: calc(100% - 4rem);
    }
    ::v-deep(.green_header h2){
        margin: 7rem 0 0;
    }
    ::v-deep(.references){
        margin: 0;
    }
}

@media only screen and (max-width: 600px) {
    ::v-deep(.left_image .text-cell:last-child),::v-deep(.right_image .text-cell:last-child){
        padding: 2rem;
        margin: 0 1rem;
        width: calc(100% - 2rem);
    }
}
</style>