import { useStore } from "vuex";

export default function() {
    const store = useStore();

    const getLayoutContent = (key) => store.getters['project/getLayoutContent'](key);

    return {
        getLayoutContent
    }

}