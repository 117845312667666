
// todo - require context error
// import upperFirst from 'lodash/upperFirst'
// import camelCase from 'lodash/camelCase'

// export function registerBaseComponents(app){
//     const requireComponent = require.context(
//         // The relative path of the components folder
//         '../components/base',
//         // Whether or not to look in subfolders
//         false,
//         // The regular expression used to match base component filenames
//         /Base[A-Z]\w+\.(vue|js)$/
//       )
      
//       requireComponent.keys().forEach(fileName => {
//         // Get component config
//         const componentConfig = requireComponent(fileName)
      
//         // Get PascalCase name of component
//         const componentName = upperFirst(
//           camelCase(
//             // Gets the file name regardless of folder depth
//             fileName
//               .split('/')
//               .pop()
//               .replace(/\.\w+$/, '')
//           )
//         )
      
//         // Register component globally
//         app.component(
//           componentName,
//           // Look for the component options on `.default`, which will
//           // exist if the component was exported with `export default`,
//           // otherwise fall back to module's root.
//           componentConfig.default || componentConfig
//         )
//       })
// }


import RouterLinkLocal from "@common/components/base/RouterLinkLocal";
import BaseLoader from "@common/components/base/BaseLoader";
import BaseButton from "@common/components/base/BaseButton";
import BaseIcon from "@common//components/base/BaseIcon";

export function registerBaseComponents(app){
    app.component('RouterLinkLocal',RouterLinkLocal);
    app.component('BaseLoader',BaseLoader);
    app.component('BaseButton',BaseButton);
    app.component('BaseIcon',BaseIcon);
}
