<template >
    <div class="alert-wrap">
        <transition-group name="list" tag="div">
            <div v-for="(message, index) in messages" :key="index" :class="`alert ${message.type}`">
                <div class="message" v-if="message.text">{{ message.text }}</div>
                <div class="message" v-else>{{ $t(message.i18n) }}</div>
                <div class="close" @click="closeAlert(index)">x</div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed,watch } from 'vue';
import { useRouter } from 'vue-router'

export default {
    name: "Alert",
    setup(){
        const store = useStore();

        const messages = computed(() => store.getters['alert/messages']);
        const clearAlert = () => store.dispatch("alert/clear"); 
        
        const router = useRouter()
        watch(router.currentRoute,clearAlert)

        const closeAlert = (index) => store.commit("alert/close",index); 

        return {
            messages,
            closeAlert
        }
    }
}
</script>

<style>
    .alert-wrap{
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
    }
    .alert{
        box-shadow: 2px 2px 20px 1px grey;
        border: 3px solid black;
        padding: 1rem;
        border-radius: 0.5rem;
        margin: 1rem auto;
        width: 30rem;
        max-width: 100%;
        display: flex;
        transition: all .5s ease
    }
    .message{
        flex-grow: 1;
    }
    .close{
        cursor: pointer;
    }
    .alert.alert-danger{
        border: 3px solid rgb(218, 39, 8);
        background: rgb(248, 145, 145);
    }
    .alert.alert-success{
        border: 3px solid rgb(2, 128, 34);
        background: rgb(145, 248, 167);
    }
    .list-enter, .list-leave-to{
        opacity: 0;
        transform: translateY(-30px)
    }

</style>
