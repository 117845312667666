<template>
    <div class="icon"></div>
</template>

<script>
export default {
    name: "BaseIcon"
}
</script>

<style scoped>
.icon{
    display: block;
    /* width: 100%;
    height: 100%; */
    margin: 5%;
    width: 90%;
    height: 90%;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
}
.icon.filter{
    background-image: url('../../assets/filter.svg');
}
.icon.plus{
    background-image: url('../../assets/plus.svg');
    margin: 15%;
    width: 70%;
    height: 70%;
}
.icon.preview{
    background-image: url('../../assets/preview.svg');
}
.icon.preview_composition{
    background-image: url('../../assets/preview_composition.svg');
}
.icon.check{
    background-image: url('../../assets/check.png');
}
.icon.close{
    background-image: url('../../assets/close.svg');
}
.icon.close_white{
    background-image: url('../../assets/close_white.svg');
}
.icon.error{
    background-image: url('../../assets/error.svg');
    max-width: 2rem;
}
.icon.trash{
    background-image: url('../../assets/trash.svg');
}
.icon.trash_white{
    background-image: url('../../assets/trash_white.svg');
}
.icon.orange_star{
    background-image: url('../../assets/orange_star.svg');
}
.icon.pen{
    background-image: url('../../assets/pen.svg');
}
.icon.plus_black{
    background-image: url('../../assets/plus_black.svg');
    max-width: 1.5rem;
    max-height: 1.5rem;
}
.icon.arrows{
    background-image: url('../../assets/arrows.svg');
    max-width: 1.5rem;
    max-height: 1.5rem;
}
.icon.red_circle{
    background-image: url('../../assets/red_circle.svg');
}
.icon.orange_circle{
    background-image: url('../../assets/orange_circle.svg');
}
.icon.green_circle{
    background-image: url('../../assets/green_circle.svg');
}
.icon.green_empty_circle{
    background-image: url('../../assets/green_empty_circle.svg');
}
.icon.home{
    background-image: url('../../assets/home.svg');
}
.icon.upload{
    background-image: url('../../assets/upload.svg');
}
.icon.go_to_page{
    background-image: url('../../assets/go_to_page.svg');
}
.icon.arrow_right{
    background-image: url('../../assets/arrow_right.svg');
}
.icon.page{
    background-image: url('../../assets/page.svg');
}
.icon.pages{
    background-image: url('../../assets/pages.svg');
}
.icon.dots{
    background-image: url('../../assets/dots.svg');
}
.icon.print{
    background-image: url('../../assets/print.svg');
}
.icon.remove{
    background-image: url('../../assets/remove.svg');
}
.icon.link{
    background-image: url('../../assets/link.svg');
}
</style>
