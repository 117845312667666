<template>
    <router-link :to="url">
        <slot></slot>
    </router-link>
</template>

<script>
 import { computed } from 'vue';
 import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
export default {
    name: "RouterLinkLocal",
    props: {
        link: {
            type: String,
            required: true,
        },
        translate: {
            type: Boolean,
            default: () => true
        }
    },
    setup(props) {
        const i18n = useI18n();
    
        const router = useRouter();

        const url = computed(() => {
            let link = `/${i18n.locale.value}`;
            if(props.translate && props.link != ''){
                props.link.split('/').forEach(element => {
                    link += '/';
                    if(element.startsWith('!')){
                        link += element.substring(1);
                    } else if(element.startsWith(':')) {
                        link += router.currentRoute.value.params[element.substring(1)];
                    } else {
                        link += i18n.t(element);
                    }
                });
            } else {
                link += props.link;
            }
            return link;
        });

        return {
            url
        }
    },
}
</script>

<style scoped>

</style>
