
import merge from 'lodash/merge'
import { createI18n } from 'vue-i18n'

function loadLocaleMessages () {
   const locales = require.context('@common/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
   const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}


export function setupI18nBase(locales) {
    const locale = process.env.VUE_APP_I18N_LOCALE || 'cz';
    const i18n = createI18n({
        locale: locale,
        fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'cz',
        messages: merge(loadLocaleMessages(),locales)
    })
    setI18nLanguage(i18n, locale)
    return i18n
}

export function setI18nLanguage(i18n, locale) {
    i18n.global.locale = locale;
    let htmlLocale = locale;
    if(locale === "cz"){
      htmlLocale = "cs"
    }
    document.querySelector('html').setAttribute('lang', htmlLocale)
}
