export function createCmsPageRoutes({locales,customBlocks={}}){
    return [
        {
            path: ':page_url',
            component: () => import(/* webpackChunkName: "page-view" */'@common/components/cms_app/PageView'),
            props: {
                customBlocks
            },
            meta: {
                public: true,
                breadcrumbs: [
                    {
                        name: '!Home',
                        link: '!'
                    },
                    {
                        store: "page/getPageName"
                    }
                ],
                title: {store: "page/getPageName"}
            },
        },  
        {
            path: locales.cz.url.page+'/:page_code',
            component: () => import(/* webpackChunkName: "page-view" */'@common/components/cms_app/PageView'),
            alias: locales.en.url.page+'/:page_code',
            props: {
                customBlocks
            },
            meta: {
                public: true,
                breadcrumbs: [
                    {
                        name: '!Home',
                        link: '!'
                    },
                    {
                        store: "page/getPageName"
                    }
                ],
                title: {store: "page/getPageName"}
            },
        },
        {
            path: locales.cz.url.page+'/error/:error_code',
            alias: locales.en.url.page+'/error/:error_code',
            name: 'page_error',
            component: () => import(/* webpackChunkName: "error-page" */'@common/pages/ErrorPage'),
            meta: {
                public: true,
            }
        },
    ]; 
}