//import { h } from 'vue'
//import { Vue } from 'vue' 

import { isLoggedIn } from '../helpers/auth';
import { createRouter, createWebHistory } from 'vue-router'
import { setI18nLanguage } from '../i18n'
import Page from '@common/pages/Page.vue'
// import { isEqual } from 'lodash'
//import NotFoundPage from '@common/pages/NotFoundPage.vue'

export function createRoutes({routes,fallbackLocale}){
    return [
        {
            path: '/',
            redirect: `/`+fallbackLocale,
        },
        {
            path: '/:lang',
            alias: '/:lang/',
            component: Page,
            children:   [
                ...routes
            ],
        },
        {
            path: '/:lang/error/:error_code',
            name: 'error',
            component: () => import(/* webpackChunkName: "error-page" */'@common/pages/ErrorPage'),
            props: true,
            meta: {
                public: true,
            }
        },
        {
            path: "/:lang/:catchAll(.*)",
            redirect: (to) => ({name: 'error',params: { lang: to.params.lang ,error_code: '404' }})
        }
    ]
}

export function setupRouterBase({
    routes,
    i18n,
    fallbackLocale = 'cz',
    SUPPORT_LOCALES = ['en', 'cz']
}){
    //const { global: composer } = i18n

    const locales = {
        en: {
            "login": "login",
        },
        cz: {
            "login": "prihlaseni",
        }
    }

        // create router instance
    const router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes: createRoutes({routes,fallbackLocale}),
    })

    // navigation guards
    router.beforeEach(async (to, from, next) => {

        let locale = to.params.lang;
        // Locale fallback
        if (!locale || locale === 'undefined' || !SUPPORT_LOCALES.includes(locale)){
            locale = fallbackLocale
            to.params.lang = locale;
            return next(`/${locale}/error/404`);
        } 
        // set i18n language
        setI18nLanguage(i18n, locale)

        const authRequired = to.meta.public ? false : true;
        const loggedIn = isLoggedIn();
    
        if (authRequired && !loggedIn) {
            //return next();
            return next(`/${locale}/${locales[locale].login}`);
        }
        return next();
    });

    router.onError(error => {
        if (/loading chunk \d* failed./i.test(error.message)) {
          window.location.reload()
        }
      })

    return router

}
